<template>

  <div :class="'jbox cols_' + props.columns">

    <NuxtLink class="cvr" :to="props.data.link">
      <img loading="lazy" class="cvri" :src="props.data.cover" :alt="props.data.title">
    </NuxtLink>

    <div class="con">

      <div v-if="!props.data.is_promo" class="stat">
        <NuxtLink v-if="props.data.category_name" class="cat" :to="'/journal/category/' + props.data.category_link">
          {{ props.data.category_name }}
        </NuxtLink>
        <div class="date">
          {{ $dayjs(props.data.view_date).utc().fromNow() }}
        </div>
        <div class="com" v-if="props.data.cnt_comments">
          {{ props.data.cnt_comments }} {{ $plurals.get('comment', props.data.cnt_comments) }}
        </div>
        <div class="vvw" v-if="props.data.cnt_view && useAuth().getAccess('view_extra')">
          {{ props.data.cnt_view }} {{ $plurals.get('view', props.data.cnt_view) }}
        </div>
      </div>

      <NuxtLink class="ttl" :to="props.data.link">
        {{ props.data.title }}
      </NuxtLink>
      
      <div v-if="props.data.is_promo" class="pr_label">
        Sponsored post
      </div>

      <div class="desc">
        {{ props.data.description }}
      </div>

    </div>

  </div>

</template>

<script setup lang="ts">

import type { Blog }  from "@/types/blog"

interface Props {
  data: Blog,
  columns?: number
}

const props = withDefaults(defineProps<Props>(), {
  columns: 2
})
</script>

<style scoped>

 

.jbox{
  display: flex;
  width: calc(100%/2 - 1.5rem);

}
  
.jbox .cvr{
      display: flex;
      height: 110px;
      width: 110px;
      overflow: hidden;
      text-align: center;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      flex-shrink: 0;
  }
.jbox .cvr .cvri{
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
.jbox .con{
    margin-left: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 120px;
    max-width: 100%;
    overflow: hidden;
    gap: 0.3rem;
  }
.jbox .con .ttl{
  font-weight: bold;
}
.jbox .con .cat{
      display: none;
  }
.jbox .con .desc{

  display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    
      /* max-height: 32px; */
      overflow: hidden;
      /* font-size: 0.9rem; */
      /* margin-top: 9px; */
      /* line-height: 1rem; */
      /* font-size: 0.8rem; */
  }
  .jbox .con .pr_label{
    color: #bfa900;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.8rem;
    margin-top: 2px;
    font-weight: bold;
  }
.jbox .con .stat{order: 3;}
.jbox .con .stat{
    display: flex;
    align-items: center;
    gap: 0.3rem;
    color: var(--gd-comment-text-gray-color);
    font-size: .7rem;
  }

  .jbox .con .stat > *:not(:last-child)::after{
    content: "·";
    margin-left: 0.3rem;
  }
  
  .jbox .con .stat .com{

  }
  .jbox .con .stat .vvw{

  }
 
 


  /* columns */


  .jbox.cols_1{
    width: calc(100%/1 - 1.5rem);
  }

  .jbox.cols_2{
    width: calc(100%/2 - 1.5rem);
  }

  .jbox.cols_3{
    width: calc(100%/3 - 1.5rem);
  }

  .jbox.cols_4{
    width: calc(100%/4 - 1.5rem);
  }



  @container pb (max-width: 600px) {
    .jbox{
      width: 100%!important;
    }
  }
 


</style>
